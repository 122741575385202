import React, { useState, useEffect } from "react";
import "./member.css";
import { NavLink } from "react-router-dom";
import David from "../../Pages/TeamMembers/David";

const Member = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    async function getData() {
      const response = await fetch(
        "https://dev.p-citizens.gwi.uni-muenchen.de//wp-json/wp/v2/slider?_embed&v=99999"
      );
      const data = await response.json();
      setPosts(data);
    }
    getData();
  }, []);

  posts.sort(function (a, b) {
    if (a.acf.memberorder < b.acf.memberorder) {
      return -1;
    }
    if (a.acf.memberorder > b.acf.memberorder) {
      return 1;
    }
    return 0;
  });
  return (
    <div className="member-div">
      {posts.map((post, i) => (
        <>
          <David post={post} index={i} />
        </>
        // <NavLink key={i} className="member-box" to={post.slug}>
        //     <img className="member-img" src={post.acf?.profileimage?.url} alt="" />
        //     <div className='member-flex'>
        //         <h3 className='member-name'>{post.acf?.name}</h3>
        //         <h4 className='member-title'>{post.acf?.title}</h4>
        //     </div>
        // </NavLink>
      ))}
    </div>
  );
};

export default Member;
