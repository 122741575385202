import React, { useState } from "react";
import { NavLink } from "react-router-dom";

// Images & logo
import logo from "../../assets/Emblem/Emblem elements/Marianne + Laurels/Transparent (black and white)/Vectors_Vector version - Marianne fugure + laurels, black.png";

// Css import

import "./nav.css";

const Nav = () => {
  const [active, setActive] = useState("nav-container");
  const [toggleIcon, setToggleIcon] = useState("nav-toggler");

  const navToggle = () => {
    active === "nav-container"
      ? setActive("nav-container nav-active")
      : setActive("nav-container");

    toggleIcon === "nav-toggler"
      ? setToggleIcon("nav-toggler toggle")
      : setToggleIcon("nav-toggler");
  };

  return (
    <header>
      <nav>
        <div className={active}>
          <a href="/">
            <img
              className="logo"
              src={logo}
              alt="Performing Citizenship Logo"
            ></img>
          </a>
          <h1>Performing Citizenship</h1>

          <div className="nav-links">
            <NavLink to="/" onClick={navToggle}>
              Home
            </NavLink>
            <NavLink to="/research" onClick={navToggle}>
              Research
            </NavLink>
            <NavLink to="/conference" onClick={navToggle}>
              Conference
            </NavLink>
            <NavLink to="/activities" onClick={navToggle}>
              Activities
            </NavLink>
            <NavLink to="/publications" onClick={navToggle}>
              Publications
            </NavLink>
            <NavLink to="/team" onClick={navToggle}>
              Team
            </NavLink>
            <NavLink to="/contact" onClick={navToggle}>
              Contact
            </NavLink>
            <a href="https://www.amateur-theatre-wiki.gwi.uni-muenchen.de/index.php/Main_Page" target="_blank">
              Wiki
            </a>
          </div>
        </div>
        <div onClick={navToggle} className={toggleIcon}>
          <div className="lineone"></div>
          <div className="linetwo"></div>
          <div className="linethree"></div>
        </div>
      </nav>
    </header>
  );
};

export default Nav;
